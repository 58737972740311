// src/contexts/SubscriptionContext.tsx
'use client'
import { useAuth, useUser } from '@clerk/nextjs';
import { Subscription } from '@prisma/client';
import { useRouter } from 'next/navigation';
import React, { createContext, useContext, useEffect, useState } from 'react';



interface SubscriptionContextValue {
    subscription: Subscription | null;
    loading: boolean;
    isActive: boolean;
}

const SubscriptionContext = createContext<SubscriptionContextValue>({
    subscription: null,
    loading: true,
    isActive: false
});

export const useSubscription = () => useContext(SubscriptionContext);

export const SubscriptionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { user } = useUser();
    const router = useRouter();
    const { orgSlug } = useAuth();

    const [subscription, setSubscription] = useState<Subscription | null>(null);
    const [loading, setLoading] = useState(true);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        const fetchSubscription = async () => {
            if (!user || !orgSlug) {
                setLoading(false);
                return;
            }

            try {
                const response = await fetch(`/api/${orgSlug}/subscription`);
                const data = await response.json();
                console.log('Fetched subscription:', data);
                if (!data.ok) {
                    if (data.message === 'Organisation not found') {
                        router.push(`/create-organization`)
                    }
                    if (data.message === 'No active subscription found') {
                        console.error('Error fetching subscription:', data.message);
                        router.push(`/${orgSlug}/settings?tab=subscriptions`);
                    }
                }
                setSubscription(data);

                // Check if the subscription is active
                if (data && data.startDate && (!data.endDate || new Date(data.endDate) > new Date())) {
                    setIsActive(true);
                } else {
                    setIsActive(false);
                }
            } catch (error: any) {
                console.error('Error fetching subscription:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSubscription();
    }, [user, orgSlug, router]);

    return (
        <SubscriptionContext.Provider value={{ subscription, loading, isActive }}>
            {children}
        </SubscriptionContext.Provider>
    );
};
